<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item to="/song-manage">歌曲管理</el-breadcrumb-item>
      <el-breadcrumb-item>草稿</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="header-title">
      <p class="l">
        您有{{ total }}个草稿待发布
      </p>
      <div class="r">
        <el-input prefix-icon="el-icon-search" v-model="params.name" @input="searchFn()"
                  placeholder="专辑/歌曲名称"></el-input>
      </div>
    </div>
    <el-table
        :data="tableData"
        :header-cell-style="{
        background: '#f7f7f7',
       fontSize: '12px',
        fontFamily:'SC-Regular',
        fontWeight: 400,
        color: '#333333'
      }"
        style="width: 100%">
      <el-table-column
          prop="date"
          label="专辑封面"
          align="center"
      >
        <template slot-scope="scope">
          <img style="width: 44px;height: 44px;" :src="scope.row.cover">
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          label="专辑名称">
      </el-table-column>
      <el-table-column
          prop="songNum"
          label="歌曲数">
      </el-table-column>
      <el-table-column
          label="提交时间"
          v-slot="{row}"
      >
        {{ $timestampToString(row.submitTime) }}
      </el-table-column>
      <el-table-column
          prop="address"
          label="操作">
        <template slot-scope="scope">
          <span class="active" @click="editFn(scope.row)">编辑</span>
          <span @click="deleteFn(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer-pagination">
      <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          @current-change="currentChange"
          background
          layout="prev, pager, next, slot"
          hide-on-single-page
          :total="total">
        <ul class="alone">
          <li class="totalPage">
            共 {{ pages }} 页
          </li>
          <li>到第
            <el-input v-model="inputVal"></el-input>
          </li>
          <li>
            <el-button @click="butfn()">确定</el-button>
          </li>
        </ul>
      </el-pagination>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="480px"
        align="center"
    >
      <span>删除后不可撤回，请确认是否删除？</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button class="activeBut" type="primary" @click="deleteApiFn(albumId)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
let PAGESIZE = 10
let timer;
import {albumList, delAlbum} from "@/api/song";

export default {
  name: "songDraft",
  data() {

    return {
      inputVal: 1,
      currentPage: 1,
      pageSize: PAGESIZE,
      dialogVisible: false,
      tableData: [],
      total: 1,
      pages: 0,
      albumId: -1,
      params: {
        issueStatus: '1,0',
        name: "",
        pageNum: 1,
        pageSize: PAGESIZE,
        status: 4
      }
    }
  },
  created() {
    this.draftFn()
    this.draftTotalFn()
  },
  methods: {
    butfn() {
      this.currentPage = Number(this.inputVal)
      this.params.pageNum = this.currentPage;
      this.draftFn()
    },
    currentChange(val) {
      this.currentPage = Number(val);
      this.inputVal = val;
      this.params.pageNum = this.currentPage;
      this.draftFn()
    },
    draftFn() {
      let p = this.params
      albumList(p).then(res => {
        if (res.code === 0) {
          let {list, pages} = res.data
          this.tableData = list;
          this.pages = pages;
        }
      })
    },
    draftTotalFn(){
      albumList({
        pageSize:1,
        status:4,
         pageNum:1
      }).then(res => {
        if (res.code === 0) {
          let {total} = res.data
          this.total = total;
        }
      })
    },
    searchFn() {
      clearInterval(timer)
      timer = setTimeout(() => {
        this.params.pageNum = 1
        this.draftFn()
      }, 1000)
    },
    //  删除专辑
    deleteFn(val) {
      this.dialogVisible = true
      this.albumId = val.id
    },
    deleteApiFn(val) {
      delAlbum({}, {albumId: val}).then(res => {
        if (res.code === 0) {
          this.draftFn()
          this.dialogVisible = false
          this.draftTotalFn()
        }
      })
    },
  // 编辑
    editFn(val){
      // console.log(val)
      this.$router.push('/release/'+ val.id)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  list-style: none;
}

.header-title {
  display: flex;
  margin: 30px 0;

  .l {
    flex: 1;
    font-size: 18px;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #333333;
  }

  .r {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    width: 275px;
    height: 40px;
    border-radius: 3px;

    ::v-deep .el-input__inner {
      border: none;
    }
  }
;
}

::v-deep .el-table {
  span {
    font-size: 12px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    margin-right: 20px;
  }

  .active {
    color: #ff004d;
  }
}

.footer-pagination {
  margin: 39px 0 39px 0;
  display: flex;
  justify-content: center;

  .alone {
    display: inline-flex;
    height: 30px;
    align-items: center;
    font-size: 12px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    color: #555555;

    .totalPage {
      width: 40px;
      margin: 0 17px 0 6px;
    }

    ::v-deep .el-input {
      .el-input__inner {
        width: 30px;
        height: 30px;
        border: 1px solid #e1e0e0;
        border-radius: 2px;
      }
    }

    .el-button {
      margin-left: 6px;
      width: 48px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #e1e0e0;
      border-radius: 2px;
    }
  }

  .alone > li {
    display: inline-block;
  }
}

::v-deep .el-dialog {
  height: 260px;

  .el-dialog__header {
    padding: 30px 0;
    margin: 0;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #222222;
    }
  }

  .el-dialog__body {
    padding: 25px 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    padding: 25px 0 40px 0;

    .el-button {
      width: 160px;
      height: 40px;
      background: #f4f4f4;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
    }

    .activeBut {
      background: #ff004d;
      color: #ffffff;
      margin-left: 32px;
    }
  }
}
</style>
